.root {
}

.content {
    width: 600px;
}

.directorsBox {
    flex-direction: row;
}

.directorsSpacer {
    width: 80px;
}

.moralSupport {
}

.codeBox {
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #111;
    overflow: auto;
}

@media all and (max-width: 800px) {
    .content {
        width: 100%;
    }

    .moralSupport {
        max-width: 100%;
    }
}

